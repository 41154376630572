import './App.css';
import {useState} from 'react';
import {useCallback} from 'react';
import featureWebp from './features.webp'
import logo from './logo-vertical.png'

const fastbuitInvestimentForFiveYears = (5 * 12) * 450

function App() {
  const [state, setState] = useState({
      cub: '',
      ap_count: '',
      size: '',
      email: null,
      role: null,
      name: null,
      phone: null,
      company: null
  })
  const [step, setStep] = useState(() => localStorage.getItem("SUBMITED") ? "SUBMITED": null)
  const handleChange = useCallback((ev) => {
      const { value, name } = ev.target
      setState(s => ({ ...s, [name]: value }))
  }, [])
  const buildingCost = (state.cub * (state.size * state.ap_count * 1.3))
  const format = f => f.toLocaleString("pt-BR", { style: 'currency', currency: "BRL" })
  const postConstructionCost = (buildingCost * 0.02) || 0
  const apCost = (buildingCost / state.ap_count) || 0
  const apartamentsLost = (postConstructionCost / apCost) || 0 

  const expectedCostReductionUsingFastBuilt = 0.2 * postConstructionCost

  const handleSubmit = (ev) => {
      ev.preventDefault()
      if (step === "ASK_INFO") {
          const b = btoa(JSON.stringify({ event: "Calculadora", info: [`Email: ${state.email}`, `Cargo: ${state.role}`, `Nome: ${state.name}`, `Telefone: ${state.phone}`, `Companhia: ${state.company}`] }))
          fetch(`https://api.fastbuilt.com.br/v1/backoffice/notify?b=${b}`).catch(err => {
            console.error(err)
          })
          setStep("SUBMITED")
          localStorage.setItem("SUBMITED", "1")
      } else if (!step) {
          setStep("ASK_INFO")
      } 


  }
  return (
    <>
        <div className="App">
            <a href="http://fastbuilt.com.br/" target="_blank" rel="noopener noreferrer">
                <img className='logo' src={logo} alt="Logo FastBuilt"/>
            </a>
            <h1> Quantos apartamentos você está <strong>perdendo</strong> com seu pós-obra? </h1>
            <h3 className='hidden-mobile'>
                <small>
                    Grande parte das construtoras não sabe qual o custo do seu
                    <br/> pós-obra em cada emprendimento, ou nem se quer uma previsão.
                </small>
            </h3>
            <div className='simulate-text hidden-mobile'>
                  <hr/>
                <h3>
                Simule sua economia com a Calculadora de Gastos
                </h3>
            <hr/>
          </div>
          <div className='row-calc'>
          <form onSubmit={handleSubmit}>
            <img className='hidden-mobile' src={featureWebp} alt="imagem pessoa com dúvida" />
            <div className='calc-container'>
            <div className='calc'>
                {
                    step !== "ASK_INFO" && (
                        <>
                            <p>
                                <small className='word-break'>
                                Preencha os dados para simular quanto
                                você gastaria
                                <br/>
                    com pós-obra
                                <br/>
                                <br/>
                                </small>
                            </p>
                            <div className='input-container'>
                              <label>
                                Valor do CUB atualizado
                              </label>
                              <div>
                                <input type="number" autoFocus name="cub" value={state.cub} onChange={handleChange} />
                                <small> R$/m² </small>
                              </div>
                            </div>
                            <div className='input-container'>
                              <label>
                                Quantidade de apartamentos
                              </label>
                              <div>
                                <input type="number" name="ap_count" value={state.ap_count} onChange={handleChange} />
                              </div>
                            </div>
                            <div className='input-container'>
                              <label>
                                Área aproximada de cada apartamento
                              </label>
                              <div>
                                  <input type="number" name="size" value={state.size} onChange={handleChange} />
                                  <small> m² </small>
                              </div>
                            </div>
                        </>
                    )
                }
                <div>
                    {step === null ? (
                        <button type="submit">
                            Quero saber meu custo com o pós-obra
                        </button>
                    ): step === "ASK_INFO" ? (
                        <>
                        <small>
                            Preencha seus dados para utilizar a calculadora
                        </small>
                        <div className='input-container'>
                          <label> E-mail </label> <input autoFocus required type="email" name="email" value={state.email} onChange={handleChange} />
                        </div>
                        <div className='input-container'>
                          <label> Nome </label> <input required type="text" name="name" value={state.name} onChange={handleChange} />
                        </div>
                        <div className='input-container'>
                          <label> Telefone </label> <input required type="tel" name="phone" value={state.phone} onChange={handleChange} />
                        </div>
                        <div className='input-container'>
                          <label> Nome da empresa </label> <input required type="text" name="company" value={state.company} onChange={handleChange} />
                        </div>
                        <div className='input-container'>
                          <label> Cargo </label> <input required type="text" name="role" value={state.role} onChange={handleChange} />
                        </div>
                        <button type="submit">
                            Quero ver o resultado
                        </button>
                        </>

                    ) : (
                        <>
                            <div className='result-container'>
                              <small> Valor aproximado do pós-obra</small>
                              <p className='error'><strong>{format(postConstructionCost)}</strong></p>

                              <br/>
                              <small> Apartamentos perdidos:</small>
                              <p className='error' ><strong>{apartamentsLost.toFixed(2)}</strong></p>

                              <br/>
                              <small> Retorne sobre o investimento usando a FastBuilt: </small>
                              <p className='success'><strong>{format(expectedCostReductionUsingFastBuilt - fastbuitInvestimentForFiveYears)}</strong>  </p>
                              <br/>
                              <a target="_blank" href="https://www.fastbuilt.com.br/demonstracao-plataforma-fastbuilt/" rel="noreferrer">
                                <button>
                                  Conhecer o sistema
                                </button>
                              </a>
                            </div>
                        </>
                    )}
                </div>
            </div>

            </div>
          </form>
          </div>
          <div className='advantages hidden-mobile'>
            <div>
              <div>
                <h3> Reduza os gastos <br/> no pós-obra </h3>
              </div>
              <h2>50%</h2>
              <p>
                de <strong>redução média </strong> das demandas dos clientes que exigem interação de atendentes
              </p>
            </div>
            <div>
              <div>
                <h3>
                  Saiba quais sistemas construtivos estão dando mais problema
                </h3>
              </div>
              <h2>20%</h2>
              <p> de economia mínima do custo de operações de atendimento </p>
            </div>
            <div>
              <div>
                <h3>
                Use as informações coletadas para os próximos empreendimentos
                </h3>
              </div>
              <h2>95%</h2>
              <p> de aumento na satisfação e fidelidade na experiêcia do seu cliente </p>
            </div>
          </div>

        </div>
        <footer>
      Desenvolvido por FastBuilt Soluções
        </footer>
    </>
  );
}

export default App;
